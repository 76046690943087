<template>
  <a-modal
    :visible="value"
    title="编辑经销商分组"
    :mask-closable="false"
    :confirm-loading="confirmLoading"
    @ok="handleConfirm"
    @cancel="handleCancel"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ flex: '0 0 80px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 80px)'}"
    >
      <a-form-model-item label="分组名称" prop="groupName">
        <a-input
          v-model.trim="form.groupName"
          allow-clear
          placeholder="请输入分组名称"
        />
      </a-form-model-item>
      <a-form-model-item label="负责人" prop="leaderUserList">
        <a-button type="primary" @click="handleStaffSelect('leaders')">添加/编辑员工</a-button>
        <div class="tag-wrap">
          <a-tag v-for="item in form.leaderUserList" :key="item.userOauthId" color="blue">
            {{ item.userOauthName }}
          </a-tag>
        </div>
      </a-form-model-item>
      <a-form-model-item label="协作人" prop="collaboratorUserList">
        <a-button type="primary" @click="handleStaffSelect('coordinators')">添加/编辑员工</a-button>
        <div class="tag-wrap">
          <a-tag v-for="item in form.collaboratorUserList" :key="item.userOauthId" color="blue">
            {{ item.userOauthName }}
          </a-tag>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>  
</template>

<script>
export default {
  name: 'RenameGroupModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {
          groupName: '',
          leaderUserList: [],
          collaboratorUserList: []
        }
      }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        groupName: [
          { required: true, message: '请输入分组名称', trigger: 'blur' }
        ],
        leaderUserList: [
          { type: 'array', required: true, message: '请选择负责人', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    handleStaffSelect(type) {
      this.$emit('staff-select', type)
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        } else {
          return false
        }
      })
    },
    handleCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
      this.resetForm()
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  } 
}
</script>

<style lang="less" scoped>
.ant-form-item {
  display: flex;
}
</style>
