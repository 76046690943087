<template>
  <section class="page">
    <div class="info mb-16">
      <div class="info__hd">
        <div class="group">
          <div class="name">
            <span class="mr-8">经销商分组：{{ groupDetail.groupName || '-' }}</span>
            <a-tooltip>
              <template slot="title">
                <div>编号：{{ groupDetail.groupCode }}</div>
                <div>ID：{{ groupDetail.groupId }}</div>
              </template>
              <a-icon class="mr-8" type="info-circle" />
            </a-tooltip>
            <a-tag class="mr-8" color="blue">公共分组</a-tag>
            <a-dropdown v-if="groupDetail.isCreator === 1 || groupDetail.isLeader === 1">
              <a-icon type="edit" theme="twoTone" />
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="handleResetDealerGroup">编辑该分组</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="handleDeleteDealerGroup">删除该分组</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div class="creator">
            <span class="mr-8">负责人：{{ groupDetail.leaderName || '-' }}<span v-if="groupDetail.isLeader === 1">（本人）</span></span>
            <span class="mr-8">协作人：{{ groupDetail.collaborator || '-' }}</span>
            <span>
              创建人：{{ groupDetail.creatorName || '-' }}
              <span v-if="groupDetail.isCreator === 1">（本人）</span>
            </span>
          </div>
        </div>
      </div>
      <div class="info__bd">
        <div class="data-item-wrap">
          <div class="data-item">
            <div class="data-item__label">经销商</div>
            <div class="data-item__value">
              <router-link
                :to="{
                  name: 'dealerList', 
                  query: { groupId: groupDetail.groupId, groupName: groupDetail.groupName }
                }"
                target="_blank"
              >
                {{ groupDetail.dealerTotal | humanReadable }}
              </router-link>
            </div>
          </div>
          <div class="data-item">
            <div class="data-item__label">同步播主分组</div>
            <div class="data-item__value">
              <router-link
                :to="{
                  name: 'authorGroupList', 
                  query: { dealerGroupName: groupDetail.groupName }
                }"
                target="_blank"
              >
                {{ groupDetail.syncAuthorCount | humanReadable }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="info__ft">
        <a-space>
          <a-button
            type="primary"
            @click="handleCopyDealerGroup"
          >
            复制
          </a-button>
          <a-button
            v-if="groupDetail.isCreator !== 1"
            type="primary"
            :loading="followLoading"
            @click="handleFollowDealerGroup"
          >
            {{ groupDetail.isFollowed === 1 ? '取消关注' : '关注' }}
          </a-button>
          <a-button
            type="primary"
            @click="handleChangeDealerGroup"
          >
            公转私
          </a-button>
          <a-button
            type="primary"
            @click="handleDeriveAuthorGroup"
          >
            派生播主分组
          </a-button>
        </a-space>
        <div class="update-time">更新时间：{{ groupDetail.mtime }}</div>
      </div>
    </div>

    <DataTable
      :group="groupDetail"
      :loading="loading"
      :data="dealerList"
      :pagination="pagination"
      :export-loading="exportLoading"
      @pagination-change="handlePaginationChange"
      @export="handleExport"
      @operate="handleOperate"
    />

    <ResetGroupModal
      ref="ResetGroupModal"
      v-model="resetDealerGroupVisible"
      :form="resetDealerGroupForm"
      :confirm-loading="resetDealerGroupLoading"
      @staff-select="e => handleCopyDealerGroupStaffSelect(e, 'reset')"
      @confirm="handleResetDealerGroupConfirm"
    />

    <CopyDealerGroupModal
      ref="copyDealerGroupModal"
      v-model="copyDealerGroupVisible"
      :form="copyDealerGroupForm"
      :confirm-loading="copyDealerGroupLoading"
      @staff-select="e => handleCopyDealerGroupStaffSelect(e, 'copy')"
      @confirm="handleCopyDealerGroupConfirm"
    />

    <BaseOrgModal
      v-model="baseOrgVisible"
      :checked-data="baseOrgCheckedData"
      :multiple="baseOrgMultiple"
      @confirm="handleBaseOrgConfirm"
      style="z-index: 10000;"
    />

    <ChangeDealerGroupModal
      ref="changeDealerGroupModal"
      v-model="changeDealerGroupVisible"
      :form="changeDealerGroupForm"
      :confirm-loading="changeDealerGroupLoading"
      @confirm="handleChangeDealerGroupConfirm"
    />

    <DeriveAuthorGroupModal
      ref="deriveAuthorGroupModal"
      v-model="deriveAuthorGroupVisible"
      :form="deriveAuthorGroupForm"
      :confirm-loading="deriveAuthorGroupLoading"
      @confirm="handleDeriveAuthorGroupConfirm"
    />
  </section>
</template>

<script>
import DataTable from './components/DataTable'
import ResetGroupModal from '@/components/ResetGroupModal'
import CopyDealerGroupModal from '@/components/CopyDealerGroupModal'
import BaseOrgModal from '@/components/BaseOrgModal'
import ChangeDealerGroupModal from '@/components/ChangeDealerGroupModal'
import DeriveAuthorGroupModal from '@/components/DeriveAuthorGroupModal'
import dealerApi from '@/api/dealer'

export default {
  name: 'AuthorGroupDetail',
  components: {
    DataTable,
    ResetGroupModal,
    CopyDealerGroupModal,
    BaseOrgModal,
    ChangeDealerGroupModal,
    DeriveAuthorGroupModal
  },
  data() {
    return {
      groupDetail: {},
      loading: false,
      dealerList: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0
      },
      exportLoading: false,
      resetDealerGroupVisible: false,
      resetDealerGroupForm: {
        groupName: '',
        leaderUserList: [],
        collaboratorUserList: [],
      },
      resetDealerGroupLoading: false,
      copyDealerGroupVisible: false,
      copyDealerGroupForm: {
        copyGroupId: '',
        copyGroupName: '',
        copyGroupType: '',
        copyGroupDealerCount: '',
        groupName: '',
        leaders: [],
        coordinators: []
      },
      copyDealerGroupLoading: false,
      baseOrgVisible: false,
      baseOrgCheckedData: [],
      baseOrgMultiple: true,
      followLoading: false,
      changeDealerGroupVisible: false,
      changeDealerGroupForm: {
        sourceGroupId: '',
        sourceGroupName: '',
        dealerTotal: '',
        groupName: '',
      },
      changeDealerGroupLoading: false,
      deriveAuthorGroupVisible: false,
      deriveAuthorGroupForm: {
        sourceGroupId: '',
        sourceGroupName: '',
        dealerTotal: '',
        authorDealerCount: '',
        authorCount: '',
        groupName: '',
        isSync: false
      },
      deriveAuthorGroupLoading: false,
      baseOrgType: '',
    }
  },
  async mounted() {
    await this.fetchDealerGroupDetail()
    this.fetchDealerGroupDealerList()
  },
  methods: {
    // 获取经销商分组详情
    async fetchDealerGroupDetail() {
      try {
        const params = {
          groupId: this.$route.params.groupId
        }
        const { code, data, msg, errmsg } = await dealerApi.fetchDealerGroupDetail(params)

        if (code === 0) {
          this.groupDetail = {
            ...data
          }

          this.resetDealerGroupForm = {
            groupName: data.groupName,
            leaderUserList: data.leaderUserList || [],
            collaboratorUserList: data.collaboratorUserList || []
          }
        } else {
          this.$message.error(msg || errmsg)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 处理分页改变
    handlePaginationChange(page, size) {
      this.pagination.page = page
      this.pagination.size = size
      this.fetchDealerGroupDealerList()
    },
    // 获取经销商分组下的经销商列表
    async fetchDealerGroupDealerList() {
      this.loading = true
      try {
        const { page, size } = this.pagination
        const params = {
          groupId: this.groupDetail.groupId,
          pageNo: page,
          pageSize: size  
        }
        const { code, data, msg, errmsg } = await dealerApi.fetchDealerGroupDealerList(params)

        this.loading = false

        if (code === 0) {
          this.dealerList = data.data || []
          this.pagination.total = data.total
        } else {
          this.$message.error(msg || errmsg)
        }
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    // 处理导出
    async handleExport() {
      this.exportLoading = true
      try {
        const params = {
          groupId: this.$route.params.groupId  
        }
        await dealerApi.exportDealerGroupDealerList(params)
        this.exportLoading = false
      } catch (e) {
        console.error(e)
        this.exportLoading = false
      }
    },
    // 处理表格操作
    handleOperate(type, record) {
      type === 'remove' && this.$confirm({
        title: '确要从分组中将该经销商删除吗？',
        okText: '确认删除',
        onOk: async () => {
          this.removeDealerGroupDealer(record)
        }
      })
    },
    // 移除经销商分组下的经销商
    async removeDealerGroupDealer(record) {
      try {
        const params = {
          groupId: this.groupDetail.groupId,
          isCommon: 1,
          dealerIds: [record.id]
        }
        const { code, message } = await dealerApi.removeDealerGroupDealer(params)

        if (code === 200) {
          this.$message.success('从分组中删除经销商成功')
          await this.fetchDealerGroupDetail()
          this.fetchDealerGroupDealerList()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 处理重命名
    handleResetDealerGroup() {
      this.resetDealerGroupVisible = true
    },
    handleResetDealerGroupConfirm() {
      this.resetDealerGroup()
    },
    // 重命名经销商分组
    async resetDealerGroup() {
      this.resetDealerGroupLoading = true
      try {
        const { groupId } = this.groupDetail
        const { groupName, leaderUserList, collaboratorUserList } = this.resetDealerGroupForm
        const params = {
          groupId,
          groupName,
          leaderUserList,
          collaboratorUserList,
          groupType: 1
        }
        const { code, message ,errmsg} = await dealerApi.renameDealerGroup(params)

        this.resetDealerGroupLoading = false

        if (code === 200) {
          this.$message.success('保存成功')
          this.resetDealerGroupVisible = false
          this.$refs.ResetGroupModal.resetForm()
          this.fetchDealerGroupDetail()
        } else {
          this.$message.error(message || errmsg)
        }
      } catch (e) {
        console.error(e)
        this.resetDealerGroupLoading = false
      }
    },
    // 处理删除该分组
    handleDeleteDealerGroup() {
      this.$confirm({
        title: '该分组为公共分组，请谨慎删除！',
        okText: '确认删除',
        onOk: async () => {
          this.deleteDealerGroup()
        }
      })
    },
    // 删除经销商分组
    async deleteDealerGroup() {
      try {
        const { groupId } = this.groupDetail
        const params = { groupId }
        const { code,  message } = await dealerApi.deleteDealerGroup(params)

        if (code === 200) {
          this.$message.success('删除经销商分组成功')
          this.$router.push({
            name: 'dealerGroupList'
          })
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 处理复制
    handleCopyDealerGroup() {
      this.copyDealerGroupVisible = true
      this.copyDealerGroupForm.copyGroupId = this.groupDetail.groupId
      this.copyDealerGroupForm.copyGroupName = this.groupDetail.groupName
      this.copyDealerGroupForm.copyGroupType = 1
      this.copyDealerGroupForm.copyGroupDealerCount = this.groupDetail.dealerTotal
    },
    handleCopyDealerGroupStaffSelect(type, selectType) {
      this.baseOrgType = selectType
      this.staffSelectType = type
      this.baseOrgVisible = true

      if (this.staffSelectType === 'leaders') {
        if(this.baseOrgType === 'copy') {
          const { leaders } = this.copyDealerGroupForm
          this.baseOrgCheckedData = leaders.length > 0 ? [leaders[0].id] : []
        } else {
          const { leaderUserList } = this.resetDealerGroupForm
          this.baseOrgCheckedData = leaderUserList.length > 0 ? [leaderUserList[0].userOauthId] : []
        }
        this.baseOrgMultiple = false
      }

      if (this.staffSelectType === 'coordinators') {
        if(this.baseOrgType === 'copy') {
          const { coordinators } = this.copyDealerGroupForm
          this.baseOrgCheckedData = coordinators.length > 0 ? coordinators.map(item => item.id) : []
        } else {
          const { collaboratorUserList } = this.resetDealerGroupForm
          this.baseOrgCheckedData = collaboratorUserList.length > 0 ? collaboratorUserList.map(item => item.userOauthId) : []
        }
        this.baseOrgMultiple = true 
      }
    },
    handleBaseOrgConfirm(val) {
      console.log({val})
      let list = []
      if(this.baseOrgType == 'copy') {
        list = val.checkedUser.map(item => {
          return { id: item.key, name: item.title }
        })
      } else {
        list = val.checkedUser.map(item => {
          return { userOauthId: item.key, userOauthName: item.title }
        })
      }
      console.log({list}, this.staffSelectType, this.baseOrgType)

      if (this.staffSelectType === 'leaders') {
        if(this.baseOrgType === 'copy') {
          this.copyDealerGroupForm.leaders = list
        } else {
          this.resetDealerGroupForm.leaderUserList = list
        }
      }

      if (this.staffSelectType === 'coordinators') {
        if(this.baseOrgType === 'copy') {
          this.copyDealerGroupForm.coordinators = list
        } else {
          this.resetDealerGroupForm.collaboratorUserList = list
        }
      }

    },
    handleCopyDealerGroupConfirm() {
      this.copyDealerPublicGroup()
    },
    // 复制经销商公共分组
    async copyDealerPublicGroup() {
      this.copyDealerGroupLoading = true
      try {
        const { copyGroupId, groupName, leaders, coordinators } = this.copyDealerGroupForm
        const params = {
          groupId: copyGroupId,
          groupName,
          leaderId: leaders[0].id,
          coordinatorIds: coordinators.map(coordinator => coordinator.id)
        }
        const { code, errmsg } = await dealerApi.copyDealerPublicGroup(params)

        this.copyDealerGroupLoading = false

        if (code === 0) {
          this.$message.success('复制成功')
          this.copyDealerGroupVisible = false
          this.$refs.copyDealerGroupModal.resetForm()
        } else {
          this.$message.error(errmsg)
        }
      } catch (e) {
        this.copyDealerGroupLoading = false
        console.error(e)
      }
    },
    // 处理关注/取消关注
    handleFollowDealerGroup() {
      if (this.groupDetail.isFollowed === 1) {
        this.unfollowDealerGroup()
      } else {
        this.followDealerGroup()
      }
    },
    // 关注经销商分组
    async followDealerGroup() {
      this.followLoading = true
      try {
        const params = {
          groupId: this.groupDetail.groupId,
          groupName: this.groupDetail.groupName,
          groupType: 1
        }
        const { code, message } = await dealerApi.followDealerGroup(params)

        this.followLoading = false

        if (code === 200) {
          this.$message.success('关注成功')
          this.fetchDealerGroupDetail()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
        this.followLoading = false
      }
    },
    // 取消关注经销商分组
    async unfollowDealerGroup() {
      this.followLoading = true
      try {
        const params = {
          groupId: this.groupDetail.groupId,
          groupName: this.groupDetail.groupName,
          groupType: 1
        }
        const { code, message } = await dealerApi.unfollowDealerGroup(params)

        this.followLoading = false

        if (code === 200) {
          this.$message.success('取消关注成功')
          this.fetchDealerGroupDetail()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
        this.followLoading = false
      }
    },
    // 处理公转私
    handleChangeDealerGroup() {
      const { groupId, groupName, dealerTotal } = this.groupDetail

      this.changeDealerGroupVisible = true
      this.changeDealerGroupForm = {
        ...this.changeDealerGroupForm,
        sourceGroupId: groupId,
        sourceGroupName: groupName,
        dealerTotal
      }
    },
    // 处理公转私确定
    async handleChangeDealerGroupConfirm() {
      this.changeDealerGroupLoading = true
      try {
        const { sourceGroupId, groupName } = this.changeDealerGroupForm
        const params = {
          sourceGroupId,
          groupName
        }
        const { code, msg, errmsg } = await dealerApi.changeDealerGroup(params)

        this.changeDealerGroupLoading = false 

        if (code === 0) {
          this.$message.success('公转私成功')
          this.changeDealerGroupVisible = false
          this.$refs.changeDealerGroupModal.resetForm()
        } else {
          this.$message.error(msg || errmsg)
        }
      } catch (e) {
        console.error(e)
        this.changeDealerGroupLoading = false
      }
    },
    // 处理派生播主分组
    handleDeriveAuthorGroup() {
      const { groupId, groupName, dealerTotal, authorDealerCount, authorCount } = this.groupDetail

      this.deriveAuthorGroupVisible = true
      this.deriveAuthorGroupForm = {
        ...this.deriveAuthorGroupForm,
        sourceGroupId: groupId,
        sourceGroupName: groupName,
        dealerTotal,
        authorDealerCount,
        authorCount,
        groupName
      }
    },
    // 处理派生播主分组确定
    async handleDeriveAuthorGroupConfirm() {
      this.deriveAuthorGroupLoading = true
      try {
        const { sourceGroupId, groupName, isSync } = this.deriveAuthorGroupForm
        const params = {
          sourceGroupId,
          groupName,
          isSync: isSync ? 1 : 0
        }
        const { code, msg, errmsg } = await dealerApi.deriveAuthorGroup(params)

        this.deriveAuthorGroupLoading = false

        if (code === 0) {
          this.$message.success('派生播主分组成功')
          this.deriveAuthorGroupVisible = false
          this.$refs.deriveAuthorGroupModal.resetForm()
          await this.fetchDealerGroupDetail()
          this.fetchDealerGroupDealerList()
        } else {
          this.$message.error(msg || errmsg)
        }
      } catch (e) {
        console.error(e)
        this.deriveAuthorGroupLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mr-8 {
  margin-right: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.page {
  padding: 0 !important;
  background: #f0f2f5 !important;
}

.info {
  display: flex;
  padding: 24px;
  height: 120px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  &__hd {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
  }

  &__bd {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
  }

  &__ft {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    position: relative;
    height: 100%;
  }
}

.group {
  .name {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }

  .creator {
    margin: 8px 0 0;
    
    span {
      margin: 0 24px 0 0;
      display: inline-block;
    }
  }
}

.data-item-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  .data-item {
    display: flex;
    flex-flow: column-reverse;
    margin: 0 24px;

    &__label {
      text-align: center;
    }
    
    &__value {
      font-size: 24px;
      text-align: center;
    }
  }
}

.update-time {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
