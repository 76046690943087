<template>
  <div class="data-table" ref="dataTable">
    <a-row class="action-bar" ref="actionBar" type="flex">
      <a-col class="action-bar-left" flex="1 0 auto">
        该分组含{{ group.principalCount | humanReadable }}个主体，
        {{ group.dealerTotal | humanReadable }}个经销商
      </a-col>
      <a-col class="action-bar-right" flex="1 0 auto">
        <a-button
            type="primary"
            @click="goToProblemStatisticsPage"
            style="margin-right: 20px"
        >
          问题统计
        </a-button>
        <a-button
            type="primary"
            :loading="exportLoading"
            @click="handleExport"
        >
          导出
        </a-button>
      </a-col>
    </a-row>

    <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-key="(record, index) => index"
        :pagination="false"
        :scroll="scroll"
    >
      <div slot="areaInfo" slot-scope="text, record">
        <div>大区：{{ record.regionNames }}</div>
        <div>城市：{{ record.cityName }}</div>
      </div>
      <div class="operate" slot="operate" slot-scope="text, record">
        <router-link
            :to="{ name: 'dealerDetail', query: { dealer_id: record.id } }"
            target="_blank"
        >
          查看详情
        </router-link>

        <template v-if="group.isCreator === 1 || group.isLeader === 1">
          <a-divider type="vertical"/>
          <a @click="handleOperate('remove', record)">从分组删除</a>
        </template>
      </div>
    </a-table>

    <BasePagination
        ref="basePagination"
        :currentPage="pagination.page"
        :pageSize="pagination.size"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
import resizeMixin from "@/mixins/dataTableMixin";

const columns = [
  {
    title: "经销商名称",
    dataIndex: "name",
    width: 200,
    customRender: (text, record, index) => {
      return record.name || "-";
    }
  },
  {
    title: "编码",
    dataIndex: "storeCode",
    width: 150,
    customRender: (text, record, index) => {
      return record.storeCode || "-";
    }
  },
  {
    title: "公司名称",
    dataIndex: "companyName",
    width: 300,
    customRender: (text, record, index) => {
      return record.companyName || "-";
    }
  },
  {
    title: "主体",
    dataIndex: "principalNames",
    width: 200,
    customRender: (text, record, index) => {
      return record.principalNames || "-";
    }
  },
  {
    title: "地区",
    key: "areaInfo",
    width: 200,
    scopedSlots: {customRender: "areaInfo"}
  },
  {
    title: "进组时间",
    dataIndex: "addGroupTime",
    width: 180,
    customRender: (text, record, index) => {
      return record.addGroupTime || "-";
    }
  },
  {
    title: "收录时间",
    dataIndex: "ctime",
    width: 180,
    customRender: (text, record, index) => {
      return record.ctime || "-";
    }
  },
  {
    title: "操作",
    key: "operate",
    width: 180,
    fixed: "right",
    scopedSlots: {customRender: "operate"}
  }
];

export default {
  name: "DataTable",
  mixins: [resizeMixin],
  props: {
    group: {
      type: Object,
      default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0
        };
      }
    },
    exportLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns,
      scroll: {}
    };
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.setTableScroll();
      });
    }
  },
  methods: {
    handlePaginationChange(page, size) {
      this.$emit("pagination-change", page, size);
    },
    handleExport() {
      this.$emit("export");
    },
    handleOperate(type, record) {
      this.$emit("operate", type, record);
    },
    setTableScroll() {
      const winHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
      const width = this.$refs.dataTable.offsetWidth;
      const height = this.$refs.dataTable.offsetHeight;
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0);
      const scroll = {};

      if(width - 48 < columnsWidth) {
        scroll.x = columnsWidth;
      }

      if(height + 142 > winHeight) {
        const actionBarHeight = this.$refs.actionBar ? this.$refs.actionBar.$el.offsetHeight : 0;
        const tableHeadHeight = document.querySelector(".ant-table-thead").offsetHeight;
        const otherHeight = actionBarHeight + tableHeadHeight + 190;

        scroll.y = `calc(100vh - ${ otherHeight }px)`;
      }

      this.scroll = scroll;
    },
    goToProblemStatisticsPage() {
      const {href} = this.$router.resolve({
        name: "problemStatistics",
        query: {
          detailData: JSON.stringify(this.group),
          isCommon: 1
        }
      });
      window.open(href, "_blank");
    }
  },
  mounted() {
    this.setTableScroll();
    this.$_resizeHandler = () => {
      this.setTableScroll();
    };
  }
};
</script>

<style lang="scss" scoped>
.data-table {
  padding: 24px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .action-bar {
    padding: 0 0 16px;
  }

  .action-bar-right {
    text-align: right;
  }

  .author-info {
    display: flex;
    align-items: center;

    &__hd {
      margin-right: 8px;
    }
  }
}
</style>
